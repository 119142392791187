import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeUp, faVolumeMute, faSpinner, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function LMSStyle({
  isTTSEnabled,
  setIsTTSEnabled,
  handleStopTTS,
  displayedText,
  initialMessage,
  question,
  inputRef,
  handleInputChange,
  handleKeyDown,
  handleInputFocus,
  handleSendMessage,
  isLoading,
  isTyping,
  handleSkipTyping,
  messagesContainerRef,
}) {
  return (
    <div className="fixed inset-0 flex items-center justify-center overflow-hidden">
      <div
        className="relative w-full h-full bg-gradient-to-br z-0 flex flex-col items-center overflow-hidden"
        style={{
          backgroundImage: `url(${
            isTyping
              ? "https://i.imgur.com/LjJkauQ.gif" // GIF when text is generating
              : "https://i.imgur.com/889tnhg.gif" // GIF when text is not generating
          })`,
          backgroundSize: "contain", // Ensures the full GIF is visible
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center", // Align GIF to the top center, white space will be added as needed
          backgroundColor: "#E9E1DB", // Set vibrant purple color for space not covered by the GIF
        }}
      >
        {/* Header Buttons */}
        <div className="absolute top-4 left-4 flex items-center space-x-4 z-[1000]">
          <button
            onClick={() => {
              if (isTTSEnabled) {
                handleStopTTS();
                setIsTTSEnabled(false);
              } else {
                setIsTTSEnabled(true);
              }
            }}
            className="p-3 rounded-full bg-red-500 hover:bg-red-600 text-white flex items-center justify-center shadow-md transition duration-300 transform hover:scale-125"
            aria-label={isTTSEnabled ? "Mute TTS" : "Unmute TTS"}
          >
            <FontAwesomeIcon icon={isTTSEnabled ? faVolumeUp : faVolumeMute} />
          </button>
        </div>

        <div
          className="flex-grow overflow-y-auto p-4 relative w-full z-10"
          ref={messagesContainerRef}
        >
          {displayedText ? (
            <div
              className="flex flex-col items-center my-2 absolute bottom-4 left-4 right-4 transform translate-y-0"
              onClick={handleSkipTyping}
            >
              <div className="p-6 rounded-[25px] bg-yellow-500 bg-opacity-80 text-white text-left text-xl relative w-full shadow-md">
                <span>{displayedText}</span>
              </div>
            </div>
          ) : (
            <div
              className="flex flex-col items-center my-2 absolute bottom-4 left-4 right-4 transform translate-y-0"
              onClick={handleSkipTyping}
            >
              <div className="p-6 rounded-[25px] bg-yellow-500 bg-opacity-80 text-white text-left text-xl relative w-full shadow-md">
                <span>{initialMessage}</span>
              </div>
            </div>
          )}
        </div>

        <div className="p-4 z-10 w-full">
          <div className="w-full h-12 border border-gray-600 flex items-center rounded-full p-2 bg-green-500 bg-opacity-90 backdrop-filter backdrop-blur-md">
            <input
              ref={inputRef}
              value={question}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              onFocus={handleInputFocus}
              className="h-full w-full p-2 outline-none bg-transparent text-left rounded-full text-lg text-black font-semibold"
              type="text"
              placeholder="Type your message here... Let's have some fun! 😊"
            />
            <button
  onClick={handleSendMessage}
  className="h-full p-3 rounded-full bg-blue-500 hover:bg-blue-600 text-white flex items-center justify-center shadow-md transition duration-300 transform hover:scale-125 ml-3"
  disabled={isLoading || isTyping || !question.trim()} // Disable if loading, typing, or input is empty
  aria-label="Send Message"
>
  {isLoading || isTyping ? (
    <FontAwesomeIcon icon={faSpinner} spin />
  ) : (
    <FontAwesomeIcon icon={faPaperPlane} />
  )}
</button>

          </div>
          <ToastContainer autoClose={3000} position="bottom-right" />
        </div>
      </div>
    </div>
  );
}

export default LMSStyle;