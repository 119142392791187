import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeUp, faVolumeMute, faSpinner, faPaperPlane } from "@fortawesome/free-solid-svg-icons";

function LMSActivityStyle({
  isTTSEnabled,
  setIsTTSEnabled,
  handleStopTTS,
  displayedText,
  handleInputChange,
  handleKeyDown,
  handleInputFocus,
  handleSendMessage,
  isLoading,
  isTyping,
  handleSkipTyping,
  messagesContainerRef,
  question,
  setQuestion,
}) {
  const [placeholderText, setPlaceholderText] = useState("Type your message here... Let's have some fun! 😊");
  const placeholderOptions = [
    "Type your message here... Let's have some fun! 😊",
    "Ask me anything... 🤔",
    "What's on your mind? 💫",
    "Let’s get started... 🚀",
    "I'm here to help! 😊",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceholderText((prev) => {
        const currentIndex = placeholderOptions.indexOf(prev);
        const nextIndex = (currentIndex + 1) % placeholderOptions.length;
        return placeholderOptions[nextIndex];
      });
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const extractOptions = (text) => {
    const options = {};
    const modifiedText = text.replace(/([1-9])\)\s*(.*?)(?=(?:[1-9]\)|$))/g, (match, option, answer) => {
      const sanitizedAnswer = String(answer).replace(/[?,]/g, "").trim();
      options[option] = sanitizedAnswer;
      return "";
    });
    return { options, modifiedText: modifiedText.trim() };
  };
  

  const { options, modifiedText } = extractOptions(displayedText);

  const handleActivityButtonClick = (value) => {
    console.log('Button value:', value, 'Type:', typeof value);
    if (!isLoading && !isTyping) {
      handleInputChange(value); // Set the value in the input box
      handleSendMessage(value);  // Automatically send the value
    }
  };
  
  
  

  return (
    <div className="fixed inset-0 flex items-center justify-center overflow-hidden">
      <div
        className="relative w-full h-full bg-gradient-to-br z-0 flex flex-col items-center overflow-hidden"
        style={{
          backgroundImage: `url(${
            isTyping
              ? "https://i.imgur.com/LjJkauQ.gif"
              : "https://i.imgur.com/889tnhg.gif"
          })`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
          backgroundColor: "#E9E1DB",
        }}
      >
        <div className="absolute top-4 left-4 flex items-center space-x-4 z-[1000]">
          <button
            onClick={() => {
              if (isTTSEnabled) {
                handleStopTTS();
                setIsTTSEnabled(false);
              } else {
                setIsTTSEnabled(true);
              }
            }}
            className="p-3 rounded-full bg-red-500 hover:bg-red-600 text-white flex items-center justify-center shadow-md transition duration-300 transform hover:scale-125"
            aria-label={isTTSEnabled ? "Mute TTS" : "Unmute TTS"}
          >
            <FontAwesomeIcon icon={isTTSEnabled ? faVolumeUp : faVolumeMute} />
          </button>
        </div>

        <div
          className="flex-grow overflow-y-auto p-4 relative w-full z-10"
          ref={messagesContainerRef}
        >
          <div
            className="flex flex-col items-center my-2 absolute bottom-4 left-4 right-4 transform translate-y-0"
            onClick={handleSkipTyping}
          >
            <div className="p-6 rounded-[25px] bg-yellow-500 bg-opacity-80 text-white text-left text-xl relative w-full shadow-md">
              <span>{modifiedText}</span>
            </div>
          </div>
        </div>

        {Object.keys(options).length > 0 && (
          <div className="grid grid-cols-3 gap-4 mb-8 px-8 w-full z-10">
            {Object.entries(options).map(([key, value]) => (
              <button
                key={key}
                onClick={() => handleActivityButtonClick(value)}
                className="p-6 rounded-xl bg-pink-500 hover:bg-pink-600 text-white text-4xl font-bold flex items-center justify-center shadow-lg transition duration-300 transform hover:scale-110 w-full"
              >
                {`${key}: ${value}`}
              </button>
            ))}
          </div>
        )}

<div className="p-4 z-10 w-full">
  <div className="w-full h-12 border border-gray-600 flex items-center rounded-full p-2 bg-green-500 bg-opacity-90 backdrop-filter backdrop-blur-md">
    <input
      value={question}
      onChange={(e) => handleInputChange(e)} // Passes event directly
      onKeyDown={handleKeyDown}
      onFocus={handleInputFocus}
      className="h-full w-full p-2 outline-none bg-transparent text-left rounded-full text-lg text-black font-semibold placeholder-black placeholder:font-semibold placeholder-opacity-70"
      type="text"
      placeholder={placeholderText}
    />
    <button
  onClick={handleSendMessage}
  className="h-full p-3 rounded-full bg-blue-500 hover:bg-blue-600 text-white flex items-center justify-center shadow-md transition duration-300 transform hover:scale-125 ml-3"
  disabled={isLoading || isTyping || !question.trim()} // Disable if loading, typing, or input is empty
  aria-label="Send Message"
>
  {isLoading || isTyping ? (
    <FontAwesomeIcon icon={faSpinner} spin />
  ) : (
    <FontAwesomeIcon icon={faPaperPlane} />
  )}
</button>

  </div>
</div>

      </div>
    </div>
  );
}

export default LMSActivityStyle;
